import React from 'react'
import Header from '../Header/Header'

function ErrorPage() {
  return (
    <>
        <Header />
        <main>
            <h1>C'è stato un erore</h1>
            <p>Non è satato possibile trovare questa pagina</p>
        </main>
    </>
  )
}

export default ErrorPage