import React, { useEffect, useState } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { Button, Modal, Box, Typography, Collapse } from '@mui/material';
import { useMediaQuery } from 'react-responsive';
// import { useNavigate } from 'react-router-dom';
import style from './Home.module.css';
import FormContacts from '../../components/FormContacts/FormContacts';
import { useNavigate } from 'react-router-dom';


function Home() {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    const [openModal, setOpenModal] = useState(false);
    const [collapseVisible, setCollapseVisible] = useState(false);

    const navigate = useNavigate()


    gsap.registerPlugin(ScrollTrigger);

    useEffect(() => {

        // Effetto di transizione
        const tl = gsap.timeline();
        tl.set('.pay-off', {
            scaleX: 0,
            transformOrigin: 'left',
        });
        tl.to('.pay-off', {
            scaleX: 1,
            transformOrigin: 'left',
            duration: 2,
            ease: 'power3.out',
        }).to('.works-button', {
            opacity: 1,
            duration: 2,
        }, -0.5);





    }, [isMobile]);

    const handleOpenModal = () => {
        setOpenModal(true);
        setCollapseVisible(true); // Mostra il componente Collapse quando il modal si apre
    };

    const handleCloseModal = () => {
        setCollapseVisible(false); // Nascondi il componente Collapse prima di chiudere il modal
        setTimeout(() => {
            setOpenModal(false); // Chiudi il modal dopo che il componente Collapse si è nascosto completamente
        }, 500);
    };

    useEffect(() => {
        const handleScroll = () => {
            // Perform actions when the scroll event occurs
            navigate('/portfolio')
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [navigate]);

    // if (isMobile) {
    //     return <div></div>;
    // }


    return (
        <>
            <div style={{ position: 'relative', backgroundColor: '#1c1d25', width: '100%', height: '100vh', zIndex: 1 }}>
                <div className={style['hero-section'] + ' hero-section'}>
                    <div className={style['pay-off'] + ' pay-off'}>
                        <h1 style={{ marginBottom: '20px' }}>Salvatore Bosco</h1>
                        <h3 style={{ marginBottom: '20px' }}>Front-end Developer</h3>
                        <Button sx={{ color: 'white' }} variant='outlined' onClick={handleOpenModal}>About me</Button>
                    </div>
                    <div className={style['works-button'] + ' works-button'} style={{ opacity: 0, width: '100&' }}>
                        <h3>Works</h3>
                        <div className={style['line'] + ' line'}></div>
                    </div>
                </div>

                <Modal open={openModal} onClose={handleCloseModal}>
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'white',
                        p: isMobile ? 1 : 4,
                        width: '80%',
                        display: 'flex',
                        flexDirection: isMobile ? 'column' : 'row',
                        justifyContent: 'space-around',
                        alignItems: 'flex-start',
                        maxWidth: 1000,
                        borderRadius: 4,
                        backgroundColor: '#2d2f3b'
                    }}>
                        <Collapse in={collapseVisible} timeout={500}>
                            <Box sx={{
                                maxWidth: isMobile ? '100%' : '400px',
                                padding: '10px',
                                textAlign: 'left',
                                width: isMobile ? '100%' : '72%',
                            }}>

                                <Typography variant="h4" component="h2" gutterBottom>
                                    About me
                                </Typography>

                                <div style={{ fontSize: '12px', color: 'white', marginBottom: 10 }}>Front-end Developer</div>

                                <div style={{ fontSize: '14px', color: 'white', textAlign: isMobile ? '100%' : '400px' }}>

                                    I'm Salvatore Bosco, a 23-year-old Italian <div style={{ color: 'rgb(255 132 0)', display: 'inline-block' }}>Front-end developer</div>. I'm passionate about <div style={{ color: 'rgb(255 132 0)', display: 'inline-block' }}>crafting</div> unique web experiences and <div style={{ color: 'rgb(255 132 0)', display: 'inline-block' }}>solving</div> design challenges. <br />
                                    With a knack for smart user interfaces and creative interactions, I bring ideas to life through <div style={{ color: 'rgb(255 132 0)', display: 'inline-block' }}>immersive web applications</div>.
                                    When I'm not immersed in code, I'm relentlessly pursuing knowledge and constantly seeking new opportunities. Ready to make a difference!
                                </div>
                            </Box>
                        </Collapse>
                        <Collapse in={collapseVisible} timeout={1500}>
                            {isMobile ? '' :
                                <Box sx={{
                                    maxWidth: '400px',
                                    padding: '10px',
                                }}>
                                    <Typography variant="h4" component="h2" gutterBottom>
                                        Contact me
                                    </Typography>
                                    <div style={{ fontSize: '14px', color: 'white', textAlign: 'justify' }}>
                                        <FormContacts />
                                    </div>
                                </Box>
                            }
                            <Button sx={{
                                display: collapseVisible ? 'block' : 'none',
                                position: 'absolute',
                                top: 10,
                                right: 20,
                                fontSize: 40
                            }} variant="text" onClick={handleCloseModal}>x</Button>
                        </Collapse>
                    </Box>
                </Modal>
            </div >
            <div className={style['case-study']}>

            </div>

        </>
    );
}

export default Home;
