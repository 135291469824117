import React, { useEffect, useState } from 'react'
import style from './Header.module.css'
import { useMediaQuery } from 'react-responsive';
import { NavLink, useNavigate } from 'react-router-dom'
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import WorkIcon from '@mui/icons-material/Work';
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';


function Header() {

  const isMobile = useMediaQuery({ maxWidth: 767 })
  const navigate = useNavigate()
  const [value, setValue] = useState('')



  useEffect(() => {
    const currentUrl = window.location.href;
    const parts = currentUrl.split('/');
    const lastPart = parts[parts.length - 1];
    setValue('/' + lastPart)
  }, [])

  return (
    <div className={style.header} style={isMobile ? {
      position: 'sticky',
      top: 0,
      paddingBottom: 'env(safe-area-inset-top)',
      zIndex: 9999
    } : {}}>
      {
        isMobile ?
          <BottomNavigation
            sx={{
              '& .MuiBottomNavigationAction-label': {
                fontSize: '16px', // Imposta la dimensione del testo del label
              },
              '& .MuiBottomNavigationAction-root': {
                color: 'balck', // Colore predefinito dell'icona e del label
              },
              '& .MuiBottomNavigationAction-root.Mui-selected': {
                color: 'white', // Cambia il colore dell'icona e del label quando viene selezionato
                fontSize: '40px'
              },
              '& .MuiBottomNavigationAction-root.Mui-selected svg': {
                fontSize: '40px'
              },
              '& .MuiSvgIcon-root': {
                fontSize: '24px', // Dimensione personalizzata dell'icona
              },
              position: 'fixed', width: '100%', bottom: 0, backgroundColor: 'green', borderTopLeftRadius: 10, borderTopRightRadius: 10
            }}
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue)
              navigate(newValue)
            }}
          >
            <BottomNavigationAction value='/' label="Home" icon={<HomeIcon />} />
            <BottomNavigationAction value='/portfolio' label="Portfolio" icon={<WorkIcon />} />
            <BottomNavigationAction value='/contacts' label="Contacts" icon={<ContactPageIcon />} />
            <BottomNavigationAction value='/about-me' label="About me" icon={<InfoIcon />} />
          </BottomNavigation>
          :
          <ul className={style.list}>
            <NavLink to={'/'}
              className={({ isActive }) => isActive ? style.active : undefined}>Home</NavLink>
            <NavLink to={'/portfolio'}
              className={({ isActive }) => isActive ? style.active : undefined}>Portfolio</NavLink>
            <NavLink to={'/contacts'}
              className={({ isActive }) => isActive ? style.active : undefined}>Contacts</NavLink>
              <NavLink to={'/about-me'}
              className={({ isActive }) => isActive ? style.active : undefined}>About me</NavLink>
          </ul>}
    </div>
  )
}

export default Header