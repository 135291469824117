
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './routes/Home/Home';
import Contacts from './routes/Contacts/Contacts';
import Portfolio from './routes/Portfolio/Portfolio';
import MainLayout from './components/MainLayout/MainLayout';
import ErrorPage from './components/ErrorPage/ErrorPage';
import Project from './routes/Project/Project';
import AboutMe from './routes/AboutMe/AboutMe';
import { useEffect, useState } from 'react';
import LoadingPage from './components/LoadingPage/LoadingPage';
import Preloader from './components/Preloader/Preloader';
import { preloaderAnim } from './animations';

const router = createBrowserRouter(
  [
    {
      path: "/",
      element: <MainLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Home />
        },
        {
          path: "/contacts",
          element: <Contacts />
        },
        {
          path: "/portfolio",
          element: <Portfolio />
        },
        {
          path: '/projects/:id',
          element: <Project />
        },
        {
          path: '/about-me',
          element: <AboutMe />
        }
      ]
    }
  ]
)

function App() {

  useEffect(() => {
    preloaderAnim()
  }, [])

  // const [isLoading, setIsLoading] = useState(true);
  //   useEffect(() => {
  //       // Simula un tempo di caricamento
  //       setTimeout(() => {
  //           setIsLoading(false);
  //       }, 4000);
  //   }, []);

  //   if (isLoading) {
  //       return < LoadingPage />
  //   }

  return (
    <>
      <Preloader />
      <RouterProvider router={router} />
    </>

  );
}

export default App;
