import Header from '../Header/Header'
import { Outlet } from 'react-router-dom'

function MainLayout() {
  
  return (
    <>
        <Header />
        <Outlet />
        <div style={{marginBottom: 'calc(env(safe-area-inset-bottom) + 50px)'}}></div>
    </>
    
  )
}

export default MainLayout