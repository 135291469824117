import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { TextField, Button, styled } from '@mui/material';
import style from './FormContacts.module.css'
// import useSWR from 'swr';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Il nome è obbligatorio'),
  email: Yup.string().email('Email non valida').required('L\'email è obbligatoria'),
  message: Yup.string().required('Il messaggio è obbligatorio')
});

const CssTextField = styled(TextField)({
    '& .MuiFormLabel-root': {
        color: '#ffffff'
      },
    '& label.Mui-focused': {
      color: '#A0AAB4',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#E0E3E7',
      },
      '&:hover fieldset': {
        borderColor: '#B2BAC2',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#6F7E8C',
      },
    },
  });

const FormContacts = () => {
  const onSubmit = async (values, { resetForm }) => {
    try {
      const response = await fetch('https://boscosalvatore.altervista.org/my-post/wp-json/portfolio/v1/submit-form', {
        method: 'POST',
        body: JSON.stringify(values),
        headers: {
          'Content-Type': 'application/json'
        }
      });

      if (response.ok) {
        // Azioni da eseguire dopo l'invio dei dati
        resetForm();
      } else {
        throw new Error('Si è verificato un errore durante l\'invio dei dati');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        message: ''
      }}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form>
          <Field
            as={CssTextField}
            name="name"
            label="Nome"
            fullWidth
            margin="normal"
            error={isSubmitting}
            helperText={<ErrorMessage name="name" component="div" className={style["error-message"]}/>}
          />
          <Field
            as={CssTextField}
            name="email"
            label="Email"
            type="email"
            fullWidth
            margin="normal"
            error={isSubmitting}
            helperText={<ErrorMessage name="email" component="div" className={style["error-message"]}/>}
          />
          <Field
            as={CssTextField}
            name="message"
            label="Messaggio"
            multiline
            rows={4}
            fullWidth
            margin="normal"
            error={isSubmitting}
            helperText={<ErrorMessage name="message" component="div" className={style["error-message"]}/>}
          />
          <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
            Invia
          </Button>
        </Form>
      )}
    </Formik>
  );
};

export default FormContacts;
