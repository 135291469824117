import { Box, LinearProgress, Typography } from '@mui/material'
import React from 'react'

function LoadingPage() {

    const [progress, setProgress] = React.useState(10);

    React.useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
        }, 20);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <div style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: '#1c1d25',
            width: '100vw',
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                <Box sx={{ width: '100%', mr: 1, maxWidth: '500px' }}>
                    <LinearProgress variant="determinate" value={progress} />
                </Box>
                <Box sx={{ minWidth: 35, marginTop: 10}}>
                    <Typography variant="h2" color="rgb(168, 87, 0)">{`${Math.round(
                        progress,
                    )}%`}</Typography>
                </Box>
            </Box>
        </div>
    )
}

export default LoadingPage