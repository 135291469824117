import { Grid } from "@mui/material";
import VerticalTabs from "../../components/VerticalTabs/VerticalTabs";
import Life from "../../components/Life/Life";


const AboutPage = () => {

    return (
        <Grid container spacing={2} sx={{ marginTop: 10 }}>
            <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {/* Prima colonna - Foto */}
                <img style={{ maxWidth: '250px' }} src="https://boscosalvatore.altervista.org/img/profilo.jpeg" alt="" />
            </Grid>
            <Grid item xs={12} md={8.8} sx={{'& > *': {backgroundColor: '#2d2f3b'}}} >
                <VerticalTabs  tabs={[
                    {
                        label: 'work life'
                    },
                    {
                        label: 'study life'
                    },
                    {
                        label: 'personal life'
                    }
                ]} tabPanels={[
                    {
                        content: <Life type={'work'} />
                    },
                    {
                        content: <Life type={'study'} />
                    },
                    {
                        content: <Life type={'personal'} />
                    }
                ]} />
            </Grid>
        </Grid>
    );
};

export default AboutPage;
