import React from 'react'
import './Preloader.css'
function Preloader() {
    return (
        <>
            <div className='preloader'>
                <div className='texts-container'>
                    <span>Welcome</span>
                    <span>to</span>
                    <span>my</span>
                </div>
                <div className='netflix'>
                    Portfolio
                </div>
                <div className='scroll'>scroll</div>
                <div className='div'></div>
            </div>

        </>
    )
}

export default Preloader