import { Box, Typography } from '@mui/material'
import style from './Contacts.module.css'
import FormContacts from '../../components/FormContacts/FormContacts'
function Contacts() {
    return (
        <div className={style.container}>
            <div style={{ width: '100%', height: '200px', display: 'flex', flexDirection: 'column', justifyContent: 'space-around', alignItems: 'center' }}>
                {/* <div>Whatsapp: ​+39 327 333 6699</div>
                
                <div>Mail: <a href="mailto:salvatorebosco.mail@gmail.com">salvatorebosco.mail@gmail.com</a></div>
                
                <div>Instagram: <a href="www.instagram.com/salvatore_tech/">@salvatore_tech</a></div> */}
                <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'white',
                        p: 4,
                        width: '80%',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-around',
                        alignItems: 'flex-start',
                        maxWidth: 1000,
                        borderRadius: 10,
                        backgroundColor: '#2d2f3b'
                    }}>
                            <Box sx={{
                                maxWidth: '400px',
                                padding: '10px'
                            }}>
                                <Typography variant="h4" component="h2" gutterBottom>
                                <div style={{ color: 'white' }}>Contact me</div>
                                </Typography>
                                <Typography variant="body1" gutterBottom>
                                    <div style={{ fontSize: '14px', color: 'black', textAlign: 'justify'}}>
                                        <FormContacts />
                                    </div>
                                </Typography>
                            </Box>
                    </Box>
            </div>
        </div>

    )
}

export default Contacts