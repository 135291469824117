import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import style from './CardPortfolio.module.css'
import { useMediaQuery } from 'react-responsive';


export default function CardPortfolio({img, title, description, link, index}) {
    const [image,setImage] = useState('') 
    const navigate = useNavigate()
    const isMobile = useMediaQuery({ maxWidth: 767 });

    useEffect(() => {
      const fetchData = async () => {
        try {
          const result = await img(); // Sostituisci con la tua Promise
          setImage(result === ''? result : result['source_url']);
          
        } catch (error) {
          console.error(error);
          // Gestisci l'errore se la Promise viene rifiutata
        }
      };

      fetchData()
    }, [img]);

    
    

  return (
    <Card className={style['card']} sx={{position: 'relative',backgroundColor: 'grey', color: 'white',width: '100%', maxWidth: 600, height: '80%',borderRadius: 5, margin: 2, border: "1px solid white"}}>
      <CardMedia
        component="img"
        alt="green iguana"
        height="140"
        image={image}
      />
      <CardContent >
        <Typography gutterBottom variant="h4" component="div">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{ __html: description }}>
        </Typography>
      </CardContent>
      <CardActions sx={{position: 'absolute', bottom: 20, left: 20}}>
        <Button sx={{color: 'rgb(168, 87, 0)', fontSize: 30}} size="medium" onClick={()=>{navigate('/projects/'+link)}}>Learn More</Button>
      </CardActions>
    </Card>
  );
}