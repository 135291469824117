import { Typography } from '@mui/material';
import style from './ImageCoverPost.module.css'
import { useMediaQuery } from 'react-responsive';

const ImageCoverPost = ({ image, title }) => {

    const isMobile = useMediaQuery({ maxWidth: 767 })

  return (
    <div className={style.imageContainer} style={isMobile ? {height: '20vh'} : {height: '16vw'}}>
      <img src={image} alt={title} className={style['img_cover']} />
      <Typography variant="h4" className={style.textOverlay}>
        {title}
      </Typography>
    </div>
  );
};

export default ImageCoverPost;
